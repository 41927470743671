import React from 'react';
import config from '@/Framework/config';
import TenantContext from '@/Framework/Tenant/TenantContext';
import CustomizationContext from '@/dealroadshow/application/Viewer/CustomizationContext';
import RoadshowContext from '@/dealroadshow/application/RoadshowContext';
import Layout from './Layout';
import ScrollBodyBackground from '@/Framework/UI/Templates/ScrollBodyBackground';
import ScrollToTop from '@/Framework/UI/Templates/ScrollToTop';

const LandingLayout = ({ children }) => (
  <TenantContext tenant={ config.tenant.dealroadshow.code }>
    <RoadshowContext>
      <CustomizationContext>
        <Layout>
          <ScrollBodyBackground tenant={ config.tenant.dealroadshow.code } />
          <ScrollToTop />
          { children }
        </Layout>
      </CustomizationContext>
    </RoadshowContext>
  </TenantContext>
);

export default LandingLayout;
