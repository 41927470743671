import React from 'react';
import StickyFooter from '@/Framework/UI/Organisms/StickyFooter';
import { footerConfig } from './config';

const Footer = () => (
  <StickyFooter
    config={ footerConfig() }
  />
);

export default Footer;
